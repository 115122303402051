<template>
  <div class="setting-main hei">
    <!-- 返回菜单 -->
    <back text="设置"></back>
    <div class="setting-main__content">
      <ul>
        <li v-for="item in list" :key="item.type" @click="onSelect(item)">
          <a href="javascript:;" class="setting-main__link">{{ item.title }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { systemInit } from "../../utils/global";
import { getMachineDetail } from "@/api/iot";
import { mapMutations } from "vuex";
export default {
  name: "settingMain",
  components: {
    Back: () => import("@/components/common/back")
  },
  data() {
    return {
      list: [
        {
          title: "修改票纸数量",
          type: "updateTicket"
        },
        {
          title: "修改外设型号",
          type: "updateAppearance"
        },
        {
          title: "清空应用缓存",
          type: "clear"
        },
        {
          title: "切换新版",
          type: "changVersion"
        }
      ]
    };
  },
  created() {},
  methods: {
    ...mapMutations("setting", ["setMachineDetail"]),
    onSelect(item) {
      switch (item.type) {
        case "updateTicket":
          // 更新一遍 纸张数量
          this.getMachineDetail();
          this.$router.push(`/settingForm?type=${item.type}`);
          break;
        case "updateAppearance":
          this.$router.push(`/settingForm?type=${item.type}`);
          break;
        case "clear":
          localStorage.clear();
          this.$message.success("清理缓存成功");
          systemInit(this);
          break;
        case "changVersion":
          localStorage.setItem("newVersion", "true");
          location.reload();
          break;
      }
    },
    // 获取自助机详情
    async getMachineDetail() {
      const res = await getMachineDetail({
        machineSnCode: localStorage.getItem("SNCODE")
      });
      this.setMachineDetail(res);
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../assets/less/custom.less";
.setting-main {
  &__content {
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 20px;
    }
    li {
      position: relative;
      width: calc(50% - 10px);
      height: 0;
      padding-top: 50%;
      margin-bottom: 20px;
      box-sizing: border-box;
      border: 1px solid @border;
      background-color: #fff;
      border-radius: 10px;
      a {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        text-align: center;
        font-size: 40px;
        color: @text;
      }
    }
  }
}
</style>
