<template>
  <div class="wrapper hei setting">
    <!-- 扫码提示语 -->
    <tips class="setting__tips"></tips>
    <!-- 键盘与版本号 -->
    <div class="res pv-4x bg-gray-dark keybord flex-grow">
      <keyboard
        v-model="numbers"
        @ok="ok"
        :default-input="false"
        :need-voice="false"
      >
        <a-input
          slot="input"
          allow-clear
          v-model="numbers"
          type="password"
          placeholder="请输入设备管理密码"
        >
        </a-input>
      </keyboard>
    </div>
    <!-- 版本号 -->
    <bottom-ota></bottom-ota>
  </div>
</template>
<script>
import Tips from "@/components/common/tips";
import Keyboard from "@/components/common/keyboard";
import BottomOta from "@/components/common/bottom-ota";
import { machineLogin } from "@/api/iot";
// import { orderSelfQueryticket } from "../../api/search";

export default {
  name: "Search",
  components: {
    Tips,
    Keyboard,
    BottomOta
  },
  props: {},
  data() {
    return {
      numbers: ""
    };
  },
  methods: {
    // 键盘确定
    ok() {
      if (!this.numbers) {
        this.$warning("请先输入密码");
        return;
      }
      this.machineLogin();
    },
    async machineLogin() {
      const res = await machineLogin({
        machineSnCode: localStorage.getItem("SNCODE"),
        operationPassword: this.numbers
      });
      if (!res) {
        this.$warning("密码错误");
        return;
      }
      this.$message.success("登录成功");
      this.$router.push("/settingMain");
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../assets/less/custom.less";
.setting {
  &__tips {
    opacity: 0;
  }
}
.keybord {
  padding-left: @1x * 10;
  padding-right: @1x * 10;
  box-sizing: border-box;
}
</style>
